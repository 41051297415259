import * as React from "react"

// @ts-ignore
import imgApple from "../../static/applepodcasts.jpg"
// @ts-ignore
import imgSpotify from "../../static/spotify.jpg"
// @ts-ignore
import imgGoogle from "../../static/googlepodcasts.jpg"
// @ts-ignore
import imgDiscord from "../../static/discord.jpg"
// @ts-ignore
import imgYoutube from "../../static/youtube.png"

interface IState {
  badges: { imgSrc; alt }[]
}

export default class BadgePodcast extends React.Component<any, any> {
  constructor(props) {
    super(props)
    this.state = {
      badges: [
        {
          alt: "Apple Podcast",
          imgSrc: imgApple,
          href: "https://podcasts.apple.com/fr/podcast/supravore/id1547615170",
        },
        {
          alt: "Spotify Podcast",
          imgSrc: imgSpotify,
          href: "https://open.spotify.com/show/11RkijxkJBX4zVqyI5tuxL",
        },
        {
          imgSrc: imgGoogle,
          alt: "Google Podcast",
          href:
            "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy80Njc1MDZlOC9wb2RjYXN0L3Jzcw==",
        },
        // {
        //   imgSrc: imgDiscord,
        //   alt: "Rejoindre le Discord",
        //   href: "https://discord.gg/ZCD7ktEXn5",
        // },
        {
          imgSrc: imgYoutube,
          alt: "YouTube",
          href: "https://www.youtube.com/channel/UCnCV0gzPo2YsP09bfKxAzGg",
        },
      ],
    }
  }

  render() {
    const { badges } = this.state
    return (
      <section className="" style={{ textAlign: "center", width: "100%", margin: "auto" }}>
        <ul className="product-grid product-grid podcast-badge-container">
          {badges.map(this.renderBadge)}
        </ul>
      </section>
    )
  }

  renderBadge({ imgSrc, alt, href }) {
    return (
      /**badges copiés de: https://www.thefitmess.com/how-to-have-more-gratitude-every-day/*/
      <div className="">
        <a href={href} target="_blank">
          <img src={imgSrc} alt={alt} width="100%" />
        </a>
      </div>
    )
  }
}
